module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"decoding":"auto","loading":"auto","backgroundColor":"none","markdownCaptions":false,"quality":50,"withWebp":false,"withAvif":false,"disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MeTrOmEnTaL","short_name":"DavLog","description":"A blog full of ideas, fiction, and raw creativity at its core","background_color":"orange","theme_color":"#FF674D","lang":"en","start_url":"/","display":"minimal-ui","icon":"static/favicon/original.png","icons":[{"src":"static/favicon/16x16.png","sizes":"16x16","type":"image/png"},{"src":"static/favicon/32x32.png","sizes":"32x32","type":"image/png"},{"src":"static/favicon/180x180.png","sizes":"180x180","type":"image/png"},{"src":"static/favicon/512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/favicon/original.png","sizes":"1024x1024","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3de1270df0cc7b90eb40c85bab7fb15a"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
